import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

// Функция для генерации частиц
const generateParticles = (count, type) => {
  const newParticles = Array.from({ length: count }, (_, i) => {
    if (type === 'clouds') {
      return {
        id: i,
        left: Math.random() * 100,
        speed: Math.random() * 10 + 20,
        type: type,
        size: Math.random() * 100 + 100,
        top: Math.random() * 60,
      };
    }
    return {
      id: i,
      left: Math.random() * 100,
      speed: Math.random() * 5 + 5,
      type: type,
    };
  });
  return newParticles;
};

// Преобразование скорости ветра
const getWindDescription = (speed) => {
  if (speed < 1) return 'Calm';
  if (speed < 4) return 'Light Breeze';
  if (speed < 8) return 'Gentle Breeze';
  if (speed < 13) return 'Moderate Breeze';
  if (speed < 19) return 'Fresh Breeze';
  if (speed < 25) return 'Strong Breeze';
  return 'Gale';
};

// Запрос погоды
const fetchWeather = async (lat, lon, setWeather, setParticles, setTemperature, setFeelsLike, setDescription, setWind, setCoords) => {
  try {
    const API_KEY = '568b53607a9c4522ec32cfc02f7d7add';
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
    );
    const weatherCondition = response.data.weather[0].main.toLowerCase();
    const weatherDescription = response.data.weather[0].description.toLowerCase();
    const temp = Math.round(response.data.main.temp);
    const feelsLikeTemp = Math.round(response.data.main.feels_like);
    const windSpeed = response.data.wind.speed;
    console.log('API Response:', response.data);
    setTemperature(temp);
    setFeelsLike(feelsLikeTemp);
    setDescription(response.data.weather[0].description);
    setWind(getWindDescription(windSpeed));
    setCoords({ lat: lat.toFixed(2), lon: lon.toFixed(2) });

    if (weatherDescription.includes('freezing rain') || weatherDescription.includes('icing')) {
      setWeather('freezing-rain');
      setParticles(generateParticles(40, 'freezing-rain'));
    } else if (weatherCondition.includes('thunderstorm')) {
      setWeather('thunderstorm');
      setParticles(generateParticles(5, 'lightning'));
    } else if (weatherCondition.includes('drizzle')) {
      setWeather('drizzle');
      setParticles(generateParticles(60, 'drizzle'));
    } else if (weatherCondition.includes('rain')) {
      setWeather('rain');
      setParticles(generateParticles(50, 'rain'));
    } else if (weatherCondition.includes('snow')) {
      setWeather('snow');
      setParticles(generateParticles(30, 'snow'));
    } else if (weatherCondition.includes('clouds')) {
      setWeather('clouds');
      setParticles(generateParticles(15, 'clouds'));
    } else if (weatherCondition.includes('clear')) {
      setWeather('sunny');
      setParticles([]);
    } else {
      setWeather('default');
      setParticles([]);
    }
  } catch (error) {
    console.error('Ошибка API:', error);
    setWeather('default');
    setTemperature(null);
    setFeelsLike(null);
    setDescription('');
    setWind('');
    setCoords({ lat: null, lon: null });
  }
};

// Компонент загрузочного экрана
const LoadingScreen = () => (
  <div className="loading-screen">
    <div className="spinner"></div>
    <p>Getting geolocation...</p>
  </div>
);

function App() {
  const [weather, setWeather] = useState('sunny');
  const [particles, setParticles] = useState([]);
  const [temperature, setTemperature] = useState(null);
  const [coords, setCoords] = useState({ lat: null, lon: null });
  const [feelsLike, setFeelsLike] = useState(null);
  const [description, setDescription] = useState('');
  const [wind, setWind] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const API_KEY = '568b53607a9c4522ec32cfc02f7d7add';
  const FALLBACK_CITY = 'Minsk';

  useEffect(() => {
    const getWeatherData = async () => {
      try {
        // Пробуем получить геолокацию
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        await fetchWeather(lat, lon, setWeather, setParticles, setTemperature, setFeelsLike, setDescription, setWind, setCoords);
      } catch (error) {
        console.log('Геолокация недоступна:', error.message);
        // Если геолокация не получена, используем Минск
        try {
          const response = await axios.get(
            `https://api.openweathermap.org/data/2.5/weather?q=${FALLBACK_CITY}&appid=${API_KEY}&units=metric`
          );
          const lat = response.data.coord.lat;
          const lon = response.data.coord.lon;
          await fetchWeather(lat, lon, setWeather, setParticles, setTemperature, setFeelsLike, setDescription, setWind, setCoords);
        } catch (fallbackError) {
          console.error('Ошибка запасного запроса для Минска:', fallbackError);
          setWeather('default');
          setTemperature(null);
          setFeelsLike(null);
          setDescription('');
          setWind('');
          setCoords({ lat: null, lon: null });
        }
      } finally {
        setIsLoading(false); // Убираем загрузочный экран в любом случае
      }
    };

    getWeatherData();
  }, [API_KEY]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={`app ${weather}`}>
      <div className="weather-container">
        {weather === 'sunny' && <div className="sun"></div>}
        {particles.map((particle) => (
          <div
            key={particle.id}
            className={
              particle.type === 'rain'
                ? 'rain-drop'
                : particle.type === 'snow'
                ? 'snowflake'
                : particle.type === 'freezing-rain'
                ? 'freezing-drop'
                : particle.type === 'drizzle'
                ? 'drizzle-drop'
                : particle.type === 'lightning'
                ? 'lightning'
                : particle.type === 'clouds'
                ? 'cloud'
                : ''
            }
            style={{
              left: `${particle.left}%`,
              animationDuration: `${particle.speed}s`,
              width: particle.size ? `${particle.size}px` : undefined,
              height: particle.size ? `${particle.size * 0.6}px` : undefined,
              top: particle.top ? `${particle.top}%` : undefined,
            }}
          ></div>
        ))}
      </div>
      <div className="info">
        {temperature !== null && (
          <div className="temperature">{temperature}°C</div>
        )}
        {feelsLike !== null && (
          <div className="status">
            Feels like {feelsLike}°C. {description}. {wind}
          </div>
        )}
        {coords.lat && coords.lon && (
          <div className="coordinates">
            {coords.lat}, {coords.lon}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;